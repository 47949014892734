/* Default styles for larger screens */
.resultsContainer {
    width: 100%;
}

.insightTable {
    width: 80%;
    margin: 0.3em auto;
}

.sideBySideInsight {
    width: 100%;
    display: flex;
    align-items: center;
}

.sideBySideInsight h4 {
    width: 10em;
}

.sideBySideInsight h5 {
    width: 4em;
}

.iconRow {
    width: 20px;
    padding: 10px;
    margin: 0 1em;
}

.divider {
    margin: 2em 0;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
    .insightTable {
        width: 100%; /* Full width for mobile */
    }

    .sideBySideInsight {
        flex-direction: column; /* Stack items vertically */
        align-items: start; /* Adjust alignment as needed */
    }

    .sideBySideInsight h4,
    .sideBySideInsight h5 {
        width: 100%; /* Full width for mobile */
    }

    .iconRow {
        margin: 1em 0; /* Adjust margin for mobile */
    }

    .divider {
        margin: 1em 0; /* Adjust margin for mobile */
    }

}
