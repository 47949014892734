/* Shared styles for containers */
.titleContainer,
.welcomeTextContainer,
.tcContainer,
.buttonContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1em;
}

li {
    padding-bottom: 0.7em;
}

/* Center the h1 within titleContainer */
.titleContainer h1 {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

/* Styling for paragraph text in welcomeTextContainer */
.welcomeTextContainer p {
    color: black;
    text-align: start;
}

/* Styling for unordered lists in welcomeTextContainer */
.welcomeTextContainer ul {
    text-align: start;
    margin: 1em 0;
}

/* Styling for labelWithCheckbox */
.labelWithCheckbox {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

/* Styling for the checkbox within labelWithCheckbox */
.labelWithCheckbox input {
    margin-right: 0.5em;
}

/* Styling for buttonContainer */
.buttonContainer {
    margin: 1em 0 2em;
    display: flex;
    justify-content: center;
}

/* Styling for the button */
.button {
    padding: 0.7em 2em;
    font-size: 18px;
    background-color: black;
    border: none;
    transition: background-color 0.3s ease;
    border-radius: 2em;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

/* Hover effect for activeButton class */
.activeButton:hover {
    background-color: #a9a9a9;
    color: black;
    cursor: pointer;
}

/* Styling for disabled button */
.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
}

.tagLine {
    text-align: center; /* Center the text within the 'tagLine' class */
    font-weight: bold;
    font-size: 20px; /* Adjust the font size as needed */
    font-style: italic;
}

.highlight {
    font-weight: bold; /* Make the text within the 'highlight' class bold */
}

.warning {
    color: red; /* Change the color of the text within the 'warning' class to red */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .screenContainer {
        width: 90%;
    }

    .titleContainer h1 {
        font-size: 1.5em;
    }

    p, li, .tcLabel {
        font-size: 0.95em;
    }

    /* Example class 'Link' added for consistency */
    .Link {
        padding: 0.5em 1.5em;
        font-size: 16px;
    }
}

@media (max-width: 360px) {
    .screenContainer {
        width: 95%;
    }

    .titleContainer h1 {
        font-size: 1.2em;
    }

    .welcomeTextContainer p {
        font-size: 12px;
    }

    /* Styling for button in smaller screens */
    .button {
        padding: 0.4em 1.2em;
        font-size: 14px;
    }
}