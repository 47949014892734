.processImage {
    max-width: 70%;
    display: block;
    margin: 0 auto;
}

.lensSummaryTable {
    max-width: 75%;
    display: block;
    margin: 0 auto;
    /* New styles for responsiveness */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

/* Custom color for the table header */
.MuiTableHead-root {
    background-color: #f0f0f0; /* Change to your desired color */
  }
  
  /* Optional: Change color of table header text */
.MuiTableCell-head {
    color: #333; /* Change to your desired text color */
}

/* Custom styles for the first, second, and third columns */
.lensSummaryTable .MuiTableCell-root:nth-child(1),
.lensSummaryTable .MuiTableCell-root:nth-child(2),
.lensSummaryTable .MuiTableCell-root:nth-child(3) {
  width: 20%; /* Adjust the width as needed */
}
  
  /* Center text for the last two columns */
  .lensSummaryTable .MuiTableCell-alignRight {
    text-align: center;
  }

  .subscribe {
    display: block;
    margin: 0 auto;
    font-size: 1.4em;
}

.templates-section {
    width: 100%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .processImage {
        max-width: 100%;
    }

    .lensSummaryTable {
        max-width: 100%;
    }

    .subscribe {
        font-size: 1em;
    }
}