/* Default styles for larger screens */
.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 1em auto;   
  width: 90%;
  padding: 1em;
  border: 0.5px solid #bebebe;
  border-radius: 5px;
  background-color: #fbfbfb;
}

.answerContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.sliderContainer {
  flex: 0.7;
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.sliderLabel {
  flex: 0.3;
  text-align: center;
  padding: 0 0.4em;
}

.sliderAnswer {
  flex: 0.7;
  margin: 0 0.2em;
  width: 8em;
}

.checkboxContainer {
  flex: 0.3;
  display: flex;
  align-items: center;
}

.checkboxWrapper {
  margin-left: auto;
  margin-right: 1em;
  display: flex; /* Add this to make the checkbox and label side by side */
  align-items: center; /* Align items vertically in the wrapper */
}

.checkboxLabel, .checkboxAnswer {
  margin: 0; /* Ensure no additional margin */
}

.followUpContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 1em auto;   
  width: 100%;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .questionContainer {
    font-size: 0.9em;
  }

  .answerContainer {
    flex-direction: column;
    align-items: start;
  }

  .checkboxContainer,
  .sliderContainer {
    width: 100%;
    margin: 0;
  }

  .checkboxContainer {
    margin-top: 0.8em;
  }

  .sliderLabel,
  .sliderAnswer,
  .checkboxWrapper {
    width: 100%;
    margin: 0;
    justify-content: center;
  }

  .questionContainer {
    font-size: 0.9em;
  }

  .sliderLabel, .checkboxLabel {
    font-size: 0.8em;
  }
}