
  .navigationButtonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 2em auto;
  }
  
  .fowardNavButton,
  .backwardButton {
    padding: 0.7em 2em;
    font-size: 1em;
    border: none;
    border-radius: 2em;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .fowardNavButton {
    background-color: black;
    color: white;
    font-weight: 600;
  }
  
  .backwardButton {
    background-color: lightgray;
    color: black;
    font-weight: 500;
  }

  .singleButton {
    margin: 0 auto;
  }

  
  /* Responsive styles for mobile screens */
  @media (max-width: 768px) {
    .fowardNavButton,
    .backwardButton {
      font-size: 0.8em; /* Adjust the font size for mobile */
      padding: 0.6em 1.5em; /* Adjust padding for mobile */
    }
  
    .summaryInputContainer,
    .opportunityInput {
      margin: 1em 0; /* Adjust margin for mobile */
    }
  
    .buttonContainer {
      flex-direction: row; /* Keep buttons in a horizontal line for mobile */
    }
  
    .navigationButtonContainer {
      flex-direction: row; /* Keep buttons in a horizontal line for mobile */
      justify-content: space-between; /* Space buttons evenly for mobile */
    }
  }