/* Default styles for larger screens */
.summaryInputContainer {
    margin: 1.5em 0;
  }
  
  .opportunityInput {
    margin: 0.5em 0;
  }
  


  