.footerContainer {
    background-color: #222222;
    height: 3.125em;
    color: white;
    margin-top: auto;
    width: 100%;
    padding: 0 3em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialContainer {
    display: flex;
    align-items: center;

}

.footerContainer p {
    color: white;
    margin: 0 1em;
}

@media (max-width: 768px) {
    .footerContainer {
      padding: 0.4em; /* Adjust padding for smaller screens */
    }
  
    .footerContainer p {
      font-size: 0.8em; /* Make font size smaller on smaller screens */
    }
  }