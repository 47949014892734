/* Base styles for all screen sizes */
.navigationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-right: auto;
  }
  
  .emptyNavSpace {
    width: 50%;
  }
  
  .mainContainer {
    display: flex;
    width: 100%;
  }
  
  .sideMenu {
    flex: 0.25;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding-top: 1em;
  }
  
  .mainArea {
    flex: 0.75;
    margin: 1em;
  }
  
  .navLink {
    padding: 0.7em;
    text-decoration: none;
    color: black;
    margin: 0.1em 0.4em;
  }
  
  .navLink:hover {
    background-color: lightgray;
  }
  
  .navLink.active,
  .navLink.active:hover {
    font-weight: 1000;
  }
  
  .activeAssessment {
    font-weight: 1000;
  }
  
  .pForge-logo {
    max-height: 3.125em;
    margin: 0.625em;
  }
  
  .appContainer {
    background-color: lightgray;
    min-height: 100vh;
  }
  
  /* Responsive styles for smaller screens */
  @media screen and (max-width: 768px) {
    .emptyNavSpace {
      display: none;
    }
  
    .mainContainer {
      flex-direction: column;
    }
  
    .sideMenu {
      flex: none;
      border: none;
      padding-top: 0;
    }
  
    .mainArea {
      flex: none;
      margin: 1em;
    }
  }

  @media screen and (max-width: 768px) {
    .emptyNavSpace {
      display: none;
    }
  
    .sideMenu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5em;
      flex: none; /* Adjust flex property based on your design */
    }

    .dividerMobile {
        width: 100%;
        margin: 0.1em 0;
    }
  
    .mainArea {
      flex: none;
      margin: 1em;
    }
  }
  
  .pForge-logo {
    max-height: 2.125em;
    margin: 0.625em;
  }