.headerContainer {
    display: flex;
    justify-content: space-between;
    background-color: black;
    height: 4.6875em;
    align-items: center;
    position: sticky;
    /*top: 0;
    z-index: 1000; */
}

.headerContainer p {
    color: white;
}

.prodivioLogoContainer {
    margin-left: 4em;
}

.rjsContainer {
    margin-right: 4em;
    display: flex;
    align-items: center;
}

.rjs-logo {
    max-height: 3.125em;
    margin: 0.625em;
}

.providio-logo {
    max-height: 3.125em;
    margin: 0.625em;
}

@media (max-width: 768px) {
    .providio-logo {
        max-height: 2em;
        margin-right: 0.2em; /* Adjust margin for the Prodivio logo on smaller screens */
    }

    .prodivioLogoContainer {
        margin-left: 0.2em;
    }

    .rjsContainer {
        margin-right: 0.2em;
    }

    .rjs-logo {
        max-height: 2.5em;
        margin-right: 0.5em; /* Adjust margin for the RJS logo on smaller screens */

    }

    .headerContainer p {
        font-size: 0.8em;
        margin-left: 0.5em; /* Adjust margin for the text on smaller screens */
    }

    .headerContainer {
        justify-content: space-around; /* Adjust this value based on your design preferences */
    }

}